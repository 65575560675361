<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="p-2 d-flex justify-content-between">
          <span>ภารกิจประจำวัน</span>
          <CreateModal @submit="upsertQuest" :gameOptions="gameOptions" />
        </div>
        <div class="px-2">
          <b-form-group class="mr-1" label="เลือกเดือน" label-for="search">
            <flat-pickr id="date" v-model="month" class="form-control"
              :config="{ ...monthSelect, onChange: selectMonthChange }" />
          </b-form-group>
        </div>
      </b-card-title>
      <div class="row px-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(quest, index) in quests">
          <b-card :title="`วันที่ ${index + 1}`" img-alt="Image" img-top tag="article" style="max-width: 20rem;"
            class="mb-2 border">
            <b-list-group size="sm">
              <b-list-group-item v-for="(item, idx) in quest.quests">{{ `${index + 1}.${idx + 1} ${item.quest_type ===
    'DEPOSIT' ? 'ฝากเงิน' : 'เล่นเกมค่าย ' + item.product} ${item.quest_amount} เครดิต`
                }}</b-list-group-item>
            </b-list-group>

            <div class="text-success py-1">รางวัล: {{ `${quest.reward} ${quest.reward_type === 'MONEY' ? 'เครดิต' :
    quest.reward_type === 'CHIP' ? 'เพชร' : 'Ticket'}` }}</div>

            <div class="d-flex flex-row">
              <b-button size="sm" class="mr-1" variant="info" @click="edit(quest)">แก้ไข</b-button>
              <b-button size="sm" class="mr-1" variant="danger" @click="deleteData(quest._id)">ลบ</b-button>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
    <EditModal @submit="upsertQuest" :gameOptions="gameOptions" :editModal="editModal" :form="form"
      @setModal="editModal = $event" @addQuest="addQuest" />
  </b-overlay>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,

} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import { Thai as TH } from 'flatpickr/dist/l10n/th';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css'
import CreateModal from './createModal.vue'
import EditModal from './editModal.vue'

export default {
  components: {
    BListGroup, BListGroupItem, BCardTitle,
    BIcon,
    BDropdownItem,
    BDropdown,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    CreateModal,
    flatPickr,
    EditModal,
  },
  // props: ['items'],
  // emits: [],
  // filters: {
  //   convertDate(date) {
  //     return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
  //   },
  // },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'index', label: 'ลำดับ' },
      { key: 'reward_type', label: 'ประเภท' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'turnover', label: 'Turnover' },
      { key: 'start', label: 'เริ่ม' },
      { key: 'end', label: 'หมดอายุ' },
      { key: 'amount', label: 'จำนวนที่เหลือ' },
      { key: 'actions', label: '#' },
    ],
    gameOptions: [],
    data: null,
    currentPage: 1,
    coupons: [],
    couponTypes: [],
    month: new Date(),
    editModal: false,
    form: {},
    selectMonth: `${moment().month() + 1}/${moment().year()}`,
    monthSelect: {
      locale: TH,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "m/Y",
        })
      ]
    },
    quests: []
  }),
  mounted() {
    // this.getCoupons()
    // this.getCouponTypes()
    this.getGameOptions();
    this.getQuests(this.selectMonth);
  },
  methods: {
    async getQuests(round) {
      try {
        const { data } = await this.$http.get('daily-quests', {
          params: {
            round: round
          }
        });

        this.quests = data;
      } catch (error) {
        console.log(error)
      }
    },
    addQuest() {
      this.form.quests.push({
        quest_type: 'DEPOSIT',
        quest_product_id: '',
        quest_amount: 0
      })
    },
    edit(form) {
      this.form = form;
      this.editModal = true;
    },
    async getGameOptions() {
      try {
        const { data } = await this.$http.get('daily-quests/game-options');

        this.gameOptions = data;
      } catch (error) {
        console.log(error)
      }
    },
    selectMonthChange(e) {
      const round = `${moment(e[0]).month() + 1}/${moment(e[0]).year()}`;
      this.selectMonth = round;
      this.getQuests(round);
    },
    async upsertQuest(form) {
      // console.log(form);
      this.isLoading = true
      try {
        const { data } = await this.$http.post('/daily-quests', { month: this.selectMonth, data: form })

        this.getQuests(this.selectMonth)
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    deleteData(id) {
      this.$swal({
        title: 'การยืนยันการลบช้อมูล?',
        text: "คุณต้องการลบภารกิจนี้ใช่หรือไม่",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true;
          try {
            await this.$http.delete(`/daily-quests/${id}`, {
              params: {
                month: this.selectMonth
              }
            })

            this.getQuests(this.selectMonth)
          } catch (e) {
            console.log(e)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async submit(form) {
      console.log(form);
      // this.isLoading = true;
      // try {
      //   const { data } = await this.$http.post('/function/coupon/list', form);

      //   this.getCoupons();
      // } catch (e) {
      //   console.log(e);
      // } finally {

      //   this.isLoading = false;
      // }
    },
  },
}
</script>
