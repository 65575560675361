<template>
  <b-modal v-model="modal" size="lg" id="editQuestModal" title="แก้ไขภารกิจ">
    <div class="row">
      <div class="col-8">
        <b-form-group label="ของรางวัล" label-for="reward_type">
          <b-form-select v-model="form.reward_type" :options="rewardOptions"></b-form-select>
        </b-form-group>
      </div>
      <div class="col-4">
        <b-form-group label="มูลค่า" label-for="reward">
          <b-form-input v-model="form.reward"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row" v-for="(quest, index) in form.quests">
      <div class="col-4">
        <b-form-group :label="`ประเภทภารกิจ ${index + 1}`" label-for="quest_type">
          <b-form-select v-model="quest.quest_type" :options="questOptions"></b-form-select>
        </b-form-group>
      </div>
      <div class="col-4" v-if="quest.quest_type === 'PLAY'">
        <b-form-group label="ค่าย" label-for="quest_product_id">
          <b-form-select v-model="quest.quest_product_id" :options="gameOptions" text-field="product_name"
            value-field="id"></b-form-select>
        </b-form-group>
      </div>
      <div class="col-4">
        <b-form-group label="จำนวน(เครดิต)" label-for="quest_amount">
          <b-form-input v-model="quest.quest_amount"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-end">
      <b-button @click="$emit('addQuest')" size="sm" variant="info">เพิ่มภารกิจ</b-button>
    </div>

    <template #modal-footer>
      <b-button variant="success" @click="submit()">
        ยืนยัน
      </b-button>
      <b-button variant="danger" @click="cancel()">
        ยกเลิก
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
  },
  directives: {
    Ripple
  },
  props: ['form', 'editModal', 'gameOptions'],
  computed: {
    modal: {
      get() {
        return this.editModal;
      },
      set(value) {
        this.$emit('setModal', value);
      }
    }
  },
  emits: ['submit', 'addQuest'],
  data: () => ({
    rewardOptions: [
      { text: 'เงิน', value: 'MONEY' },
      { text: 'เพชร', value: 'CHIP' },
      { text: 'Ticket', value: 'FREESPIN' },
    ],
    questOptions: [
      { text: 'ฝากเงิน', value: 'DEPOSIT' },
      { text: 'เล่นเกม', value: 'PLAY' },
    ],
  }),
  methods: {
    submit() {
      this.$emit('submit', this.$props.form)
      this.modal = false
      this.clearForm();
    },
    cancel() {
      this.modal = false;
      this.clearForm();
    },
    clearForm() {
      this.form = {
        reward_type: 'MONEY',
        reward: 0,
        quests: [
          { quest_type: 'DEPOSIT', quest_amount: 0, quest_product_id: '' }
        ]
      }
    }
  }
}
</script>